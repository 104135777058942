import { TimeFilter } from "@finbackoffice/fe-core";
import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useCallback,
    useMemo,
    useState,
} from "react";

export interface ITournamentIdData {
    sportId: number;
    categoryId: number;
    tournamentId: number;
}

type IPrematchContext = {
    tournaments: ITournamentIdData[];
    timeFilter: TimeFilter | null;
    gameDoesNotExist: boolean;
    activeGameId: number | null;
    addTournament: (payload: ITournamentIdData) => void;
    removeTournament: (id: number) => void;
    changeTournament: (payload: ITournamentIdData) => void;
    clearTournaments: () => void;
    setTimeFilter: Dispatch<SetStateAction<TimeFilter | null>>;
    setGameDoesNotExist: Dispatch<SetStateAction<boolean>>;
    setActiveGameId: Dispatch<SetStateAction<number | null>>;
};

export const PrematchContext = createContext<IPrematchContext>(null as any);

export const PrematchProvider: FC<PropsWithChildren> = ({ children }) => {
    const [tournaments, setTournaments] = useState<ITournamentIdData[]>([]);
    const [timeFilter, setTimeFilter] = useState<TimeFilter | null>(null);
    const [gameDoesNotExist, setGameDoesNotExist] = useState(false);
    const [activeGameId, setActiveGameId] = useState<number | null>(null);

    const addTournament = useCallback((payload: ITournamentIdData) => {
        const { sportId, categoryId, tournamentId } = payload;

        setTournaments((state) => [...state, { sportId, categoryId, tournamentId }]);
    }, []);

    const removeTournament = useCallback((id: number) => {
        setTournaments((state) => state.filter((tournament) => tournament.tournamentId !== id));
    }, []);

    const changeTournament = useCallback((payload: ITournamentIdData) => {
        const { sportId, categoryId, tournamentId } = payload;
        setTournaments([{ sportId, categoryId, tournamentId }]);
    }, []);

    const clearTournaments = useCallback(() => {
        setTournaments([]);
    }, []);

    const value = useMemo(
        () => ({
            timeFilter,
            tournaments,
            activeGameId: activeGameId ?? null,
            gameDoesNotExist,
            addTournament,
            removeTournament,
            changeTournament,
            clearTournaments,
            setTimeFilter,
            setGameDoesNotExist,
            setActiveGameId,
        }),
        [
            activeGameId,
            addTournament,
            changeTournament,
            gameDoesNotExist,
            removeTournament,
            clearTournaments,
            timeFilter,
            tournaments,
        ],
    );

    return <PrematchContext.Provider value={value}>{children}</PrematchContext.Provider>;
};
