import { SportTypes } from "@finbackoffice/fe-core";

export const SIRWidgetConfigs = () => {
    const supportedSports = [
        SportTypes.SOCCER,
        SportTypes.TENNIS,
        SportTypes.BASKETBALL,
        SportTypes.ICE_HOCKEY,
        SportTypes.BASEBALL,
        SportTypes.TABLE_TENNIS,
        SportTypes.VOLLEYBALL,
    ];
    const supportedCompactSports = [
        SportTypes.SOCCER,
        SportTypes.TENNIS,
        SportTypes.BASKETBALL,
        SportTypes.ICE_HOCKEY,
        SportTypes.BASEBALL,
    ];
    const sportRegionTeamInverted = {
        [SportTypes.BASKETBALL]: [
            15, // USA
            876, // Canada
            509, // South America
            375, // South Korea
            483, // Japan
            2127, // Electronic Leagues
        ],
        [SportTypes.BASKETBALL_3X3]: [
            1713, // USA
            2178, // South Korea
            1739, // Japan
        ],
        [SportTypes.VOLLEYBALL]: [
            814, // USA
            1116, // Canada
            447, // South Korea
            561, // Japan
        ],
        [SportTypes.ICE_HOCKEY]: [
            37, // USA
            176, // Canada
            260, // North America
            2269, // South Korea
            2339, // Japan
            2133, // Electronic Leagues
        ],
        [SportTypes.AMERICAN_FOOTBALL]: [],
    };
    return { supportedSports, supportedCompactSports, sportRegionTeamInverted };
};
