import { SportTypes } from "@finbackoffice/fe-core";

export enum ChannelEnum {
    LIVE = "live",
    PREMATCH = "prematch",
}

export const StatScoreWidgetConfigs = () => {
    const sportRegionTeamInverted = {
        [SportTypes.BASKETBALL]: [
            15, // USA
            876, // Canada
            509, // South America
            375, // South Korea
            483, // Japan
            2127, // Electronic Leagues
        ],
        [SportTypes.BASKETBALL_3X3]: [
            1713, // USA
            2178, // South Korea
            1739, // Japan
        ],
        [SportTypes.VOLLEYBALL]: [
            814, // USA
            1116, // Canada
            447, // South Korea
            561, // Japan
        ],
        [SportTypes.ICE_HOCKEY]: [
            37, // USA
            176, // Canada
            260, // North America
            2269, // South Korea
            2339, // Japan
            2133, // Electronic Leagues
        ],
        [SportTypes.AMERICAN_FOOTBALL]: [],
    };

    function getStatScoreConfigBySport(channel: ChannelEnum) {
        switch (channel) {
            case ChannelEnum.LIVE:
                return "63615f8e216798a3b53c7016";
            case ChannelEnum.PREMATCH:
            default:
                return "63616321216798a3b53c7017";
        }
    }

    return { getStatScoreConfigBySport, sportRegionTeamInverted };
};
