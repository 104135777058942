import { MINUTE_IN_MS, HeaderLayoutEnum, ISiteConfigLayouts } from "@finbackoffice/fe-core";
import {
    AuthContext,
    ModalsContext,
    PopupBannersContext,
    SiteNotificationsContext,
    UserAccountContext,
    useDocumentVisible,
    useRuntimeConfig,
    useSiteConfig,
} from "@finbackoffice/site-core";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import {
    FC,
    RefObject,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Button from "components/base/button/Button";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import Translate from "components/base/translate/Translate";
import { useLatestExchangeRates } from "hooks";
import { ModalTypes, RouterQuery } from "utils/constants";
import ErrorBoundary from "../base/error-boundary/ErrorBoundary";
import styles from "./header.module.sass";
import { PopupBanners } from "./popup-banners/PopupBanners";
import SiteNotifications from "./site-notifications/SiteNotifications";

const V1 = dynamic(() => import("./layouts/v1/V1"), {
    ssr: true,
});

const V2 = dynamic(() => import("./layouts/v2/V2"), {
    ssr: true,
});

export type LoginViewType = "login" | "forgot";

type IHeaderContext = {
    isUserLoggedIn: boolean;
    loginDefaultView?: LoginViewType;
    showExchangeRatePanel: boolean;
};

export const HeaderContext = createContext<IHeaderContext>(null as any);

const Header: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const showExchangeRatePanel = useSiteConfig<boolean>("showExchangeRatePanel");
    const router = useRouter();
    const { hasPopups, popups } = useContext(PopupBannersContext);
    const { hasNotifications, notifications } = useContext(SiteNotificationsContext);
    const { logout } = useContext(UserAccountContext);
    const { loginModalRef, signupModalRef } = useContext(ModalsContext);
    const inactivityModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const { isUserLoggedIn } = useContext(AuthContext);
    const [loginDefaultView, setLoginDefaultView] = useState<LoginViewType | undefined>();
    const isTabActive = useDocumentVisible(
        COMMON_SITE_CONFIGS.detectInactivity.enable && isUserLoggedIn,
    );
    const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useLatestExchangeRates();

    useEffect(() => {
        if (router.query?.directory === "forgot") {
            setLoginDefaultView("forgot");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.directory]);

    useEffect(() => {
        if (router.query?.type === RouterQuery.Signup && signupModalRef.current) {
            signupModalRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.type]);

    useEffect(() => {
        if (router.query?.type === RouterQuery.Login && loginModalRef.current) {
            loginModalRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.type]);

    useEffect(() => {
        if (COMMON_SITE_CONFIGS.detectInactivity.enable) {
            if (!isTabActive) {
                inactivityTimeoutRef.current = setTimeout(async () => {
                    logout();

                    inactivityModalRef.current?.open();
                }, COMMON_SITE_CONFIGS.detectInactivity.period * MINUTE_IN_MS);
            } else {
                if (inactivityTimeoutRef.current) {
                    clearTimeout(inactivityTimeoutRef.current);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTabActive]);

    const renderLayout = useMemo(() => {
        switch (siteLayoutsConfig.header) {
            case HeaderLayoutEnum.V2:
                return <V2 />;
            default:
                return <V1 />;
        }
    }, [siteLayoutsConfig.header]);

    const closeInactivityModal = useCallback(() => {
        inactivityModalRef.current?.close();
    }, []);

    const openLoginModal = useCallback(() => {
        loginModalRef.current?.open();
    }, [loginModalRef]);

    const renderInactivityModal = useMemo(
        () => (
            <>
                <p>
                    <Translate
                        tid="inactivity_text"
                        replace={{ period: COMMON_SITE_CONFIGS.detectInactivity.period }}
                    />
                </p>
                <div>
                    <Button type="button" variant="greyBtn" onClick={closeInactivityModal}>
                        <Translate tid="base_ok" />
                    </Button>
                    <Button type="button" variant="secondary" onClick={openLoginModal}>
                        <Translate tid="header_login" />
                    </Button>
                </div>
            </>
        ),
        [COMMON_SITE_CONFIGS.detectInactivity.period, closeInactivityModal, openLoginModal],
    );

    const value = useMemo(
        () => ({
            isUserLoggedIn,
            loginDefaultView,
            showExchangeRatePanel,
        }),
        [isUserLoggedIn, loginDefaultView, showExchangeRatePanel],
    );

    return (
        <HeaderContext.Provider value={value}>
            {renderLayout}
            {hasNotifications && (
                <ErrorBoundary name={SiteNotifications.name}>
                    <SiteNotifications notifications={notifications} />
                </ErrorBoundary>
            )}

            {hasPopups && (
                <ErrorBoundary name={PopupBanners.name}>
                    <PopupBanners banners={popups} />
                </ErrorBoundary>
            )}
            {COMMON_SITE_CONFIGS.detectInactivity.enable && (
                <Modal
                    ref={inactivityModalRef}
                    styleClass={styles.inactivityModal}
                    type={ModalTypes.INACTIVITY}>
                    {renderInactivityModal}
                </Modal>
            )}
        </HeaderContext.Provider>
    );
};

export default Header;
