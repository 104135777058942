import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { StatScoreWidgetConfigs } from "../components/base/widget/statscore/StatScoreWidgetConfigs";

type IStatScoreWidgetContext = {
    scriptLoaded?: boolean;
    checkIfParticipantInverted?: (sportId: number, regionId: number) => boolean;
};

export const StatScoreWidgetContext = createContext<IStatScoreWidgetContext>({});

export const StatScoreWidgetProvider: FC<PropsWithChildren> = ({ children }) => {
    const { sportRegionTeamInverted } = StatScoreWidgetConfigs();
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

    const initScript = useCallback(() => {
        if (!document.getElementById("statscore-widget-script")) {
            const script = document.createElement("script");
            script.id = "statscore-widget-script";
            script.appendChild(
                document.createTextNode(`!function(){var d="STATSCOREWidgetsEmbederScript";if(!window.document.getElementById(d)){
                window.STATSCOREWidgets={},window.STATSCOREWidgets.onLoadCallbacks=[],window.STATSCOREWidgets.onLoad=function(d){
                window.STATSCOREWidgets.onLoadCallbacks.push(d)};var n=window.document.createElement("script");
                n.src="https://wgt-s3-cdn.statscore.com/bundle/Embeder.js",n.async=!0,n.id=d,n.addEventListener("error",function(d){
                for(var n=0;n<window.STATSCOREWidgets.onLoadCallbacks.length;n++)window.STATSCOREWidgets.onLoadCallbacks[n](d)}),
                window.document.body.appendChild(n)}}();
`),
            );
            document.body.appendChild(script);
            setScriptLoaded(true);
        } else {
            setScriptLoaded(true);
        }
    }, []);

    const checkIfParticipantInverted = useCallback(
        (sportId: number, regionId: number): boolean => {
            return sportRegionTeamInverted[sportId]
                ? sportRegionTeamInverted[sportId].length
                    ? sportRegionTeamInverted[sportId].includes(regionId)
                    : true
                : false;
        },
        [sportRegionTeamInverted],
    );

    useEffect(() => {
        initScript();
    }, [initScript]);

    const value = useMemo(
        () => ({
            scriptLoaded,
            checkIfParticipantInverted,
        }),
        [scriptLoaded, checkIfParticipantInverted],
    );

    return (
        <StatScoreWidgetContext.Provider value={value}>{children}</StatScoreWidgetContext.Provider>
    );
};
