import { useRouter } from "next/router";
import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { RouteNames } from "utils/constants";

const WIDTH = 1385;

interface ISportsbookContext {
    sidebarCollapsed: boolean;
    setSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
}

export const SportsbookContext = createContext<ISportsbookContext>(null as any);

export const SportsbookProvider: FC<PropsWithChildren> = ({ children }) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const router = useRouter();
    const isMultiView = router.pathname.includes(RouteNames.MULTI_VIEW);

    const windowResizeHandler = useCallback(() => {
        if (!isMultiView) {
            if (window.innerWidth < WIDTH) {
                setSidebarCollapsed(true);
            } else {
                setSidebarCollapsed(false);
            }
        }
    }, [isMultiView]);

    useEffect(() => {
        window.addEventListener("resize", windowResizeHandler);

        return () => {
            window.removeEventListener("resize", windowResizeHandler);
        };
    }, [windowResizeHandler]);

    useEffect(() => {
        windowResizeHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = useMemo(() => ({ sidebarCollapsed, setSidebarCollapsed }), [sidebarCollapsed]);

    return <SportsbookContext.Provider value={value}>{children}</SportsbookContext.Provider>;
};
