import { IClientError, IEventFeedResponse } from "@finbackoffice/websocket-client";
import { HOUR_IN_MS, sortByScheduledTime } from "@finbackoffice/fe-core";
import { useWebsocketUpdates } from "@finbackoffice/site-core";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { EventStatus } from "@finbackoffice/enums";
import { PrematchContext } from "contexts";
import { RouteNames } from "utils/constants";

const statuses = [EventStatus.NotActive, EventStatus.Live, EventStatus.Settled, EventStatus.Ended];

type IUsePrematchTournament = {
    games: IEventFeedResponse[];
    loading: boolean;
};

type IGamesState = {
    loading: boolean;
    data: IEventFeedResponse[];
};

export const usePrematchTournament = (
    tournamentId: number,
    isFirst: boolean,
): IUsePrematchTournament => {
    const { setGameDoesNotExist, gameDoesNotExist, clearTournaments, setActiveGameId } =
        useContext(PrematchContext);
    const router = useRouter();
    const [games, setGames] = useState<IGamesState>({
        loading: true,
        data: [],
    });
    const [gamesMap] = useState<Map<number, IEventFeedResponse>>(new Map());
    const currentTimeFilter = null;
    const [, , , game_id] = router.query.prematchGameRoutes || [];
    const nextGame = games.data.find((game) => game.id.toString() !== game_id);
    const shouldRedirectToNextGame = gameDoesNotExist && nextGame;
    const shouldRedirectToHomePrematch = gameDoesNotExist && !nextGame;

    const isFirstRef = useRef<boolean>();

    const gamesUpdateHandler = useCallback(
        (data: IEventFeedResponse[], error: IClientError) => {
            if (!error) {
                data.forEach((event) => {
                    if (statuses.indexOf(event.status) !== -1) {
                        gamesMap.delete(event.id);
                    } else {
                        gamesMap.set(event.id, event);
                    }
                });
                const games = Array.from(gamesMap.values());
                const sorted_games = games.sort(sortByScheduledTime);

                setGames((state) => ({
                    ...state,
                    loading: false,
                    data: sorted_games,
                }));
            } else {
                console.error(error);
            }
        },
        [gamesMap],
    );

    const getPayload = useMemo(() => {
        let payload: any = {
            tournament_id: tournamentId,
        };

        if (currentTimeFilter) {
            const date: Date = new Date();
            payload = {
                ...payload,
                from: date.getTime(),
                to: date.getTime() + currentTimeFilter * HOUR_IN_MS,
            };
        }

        return payload;
    }, [currentTimeFilter, tournamentId]);

    useWebsocketUpdates("subscribe_tournament", gamesUpdateHandler, true, getPayload);

    useEffect(() => {
        return () => {
            if (tournamentId) {
                setGames((state) => ({
                    ...state,
                    loading: true,
                    data: [],
                }));
                gamesMap.clear();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId, currentTimeFilter]);

    const redirectToNextGame = useCallback(async () => {
        if (isFirstRef.current && nextGame) {
            await router.replace({
                pathname: `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/[[...prematchGameRoutes]]`,
                query: {
                    ...router.query,
                    prematchGameRoutes: [
                        nextGame.sport_slug,
                        nextGame.category_id.toString(),
                        nextGame.tournament_id.toString(),
                        nextGame.id.toString(),
                    ],
                },
            });
        }

        setGameDoesNotExist(false);
    }, [nextGame, router, setGameDoesNotExist]);

    const redirectToPrematchHome = useCallback(async () => {
        await router.replace({
            pathname: `/${RouteNames.SPORT}/${RouteNames.PREMATCH}`,
            query: {},
        });

        clearTournaments();

        setActiveGameId(null);

        setGameDoesNotExist(false);
    }, [clearTournaments, router, setActiveGameId, setGameDoesNotExist]);

    useEffect(() => {
        isFirstRef.current = isFirst;
    }, [isFirst]);

    useEffect(() => {
        if (!shouldRedirectToNextGame) {
            return;
        }

        redirectToNextGame();
    }, [redirectToNextGame, shouldRedirectToNextGame]);

    useEffect(() => {
        if (!shouldRedirectToHomePrematch) {
            return;
        }

        redirectToPrematchHome();
    }, [redirectToPrematchHome, shouldRedirectToHomePrematch]);

    return { games: games.data, loading: games.loading };
};
