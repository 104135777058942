import { RefObject, useEffect } from "react";

export const useClickAway = (
    ref: RefObject<HTMLElement>,
    onClickAway: (event: Event) => void,
    ignoreClassName?: string,
) => {
    const handleClickOutside = (event: Event) => {
        if (
            event.target instanceof HTMLElement &&
            ref.current &&
            !ref.current.contains(event.target)
        ) {
            if (ignoreClassName && event.target.closest(`.${ignoreClassName}`)) {
                return;
            }

            onClickAway(event);
        }
    };

    useEffect(() => {
        document.addEventListener("mouseup", handleClickOutside);
        document.addEventListener("touchend", handleClickOutside);

        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
            document.removeEventListener("touchend", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
