import { useEffect, useState } from "react";
import { IMarketFeedResponse, IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { IOutcomeData, MainMarketsMap } from "@finbackoffice/fe-core";

enum OutcomeMagicNumbers {
    HOME = "1",
    DRAW = "X",
    AWAY = "2",
}

interface IMainOddsState {
    homeOutcome: IMarketOutcomeFeedResponse | null;
    drawOutcome: IMarketOutcomeFeedResponse | null;
    awayOutcome: IMarketOutcomeFeedResponse | null;
}

export const useMainMarketOdds = (sportId: number, markets: IMarketFeedResponse[]) => {
    const [mainMarket, setMainMarket] = useState<IMarketFeedResponse>();
    const [mainOdds, setMainOdds] = useState<IMainOddsState>();
    const mainMarketName = mainMarket?.name || "";
    const mainMarketStatus = mainMarket?.status;
    const mainMarketTemplateId = mainMarket?.template_id || null;
    const mainMarketId = mainMarket?.id || null;
    const columnCount: string =
        (mainMarket?.outcomes_count ?? 0) > 2 ? "three_columns" : "two_columns";

    useEffect(() => {
        const marketGroupId: number = MainMarketsMap[sportId];
        setMainMarket(markets?.find((market) => market.template_id === marketGroupId));
    }, [markets, sportId]);

    useEffect(() => {
        let homeOutcome: IOutcomeData | null = null;
        let drawOutcome: IOutcomeData | null = null;
        let awayOutcome: IOutcomeData | null = null;

        if (mainMarket) {
            mainMarket.outcomes.forEach((outcome) => {
                if (outcome.outcome_id === 2) {
                    drawOutcome = { ...outcome, name: OutcomeMagicNumbers.DRAW };
                } else if (!homeOutcome) {
                    homeOutcome = { ...outcome, name: OutcomeMagicNumbers.HOME };
                } else if (!awayOutcome) {
                    awayOutcome = { ...outcome, name: OutcomeMagicNumbers.AWAY };
                }
            });
        }

        setMainOdds({ homeOutcome, drawOutcome, awayOutcome });
    }, [mainMarket]);

    return {
        mainOdds,
        mainMarket,
        mainMarketName,
        mainMarketStatus,
        mainMarketTemplateId,
        mainMarketId,
        columnCount,
    };
};
