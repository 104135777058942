import {
    useState,
    forwardRef,
    useImperativeHandle,
    useEffect,
    RefObject,
    useRef,
    useCallback,
    ForwardRefExoticComponent,
    ReactElement,
    useContext,
    RefAttributes,
} from "react";
import { useRouter } from "next/router";
import { createPortal } from "react-dom";
import classnames from "classnames";
import { ModalsContext } from "@finbackoffice/site-core";
import FadeInAnimation from "../fade-in/FadeInAnimation";
import styles from "./modal.module.sass";

interface IModalProps {
    children: ReactElement;
    styleClass?: string;
    type: string;
    closable?: boolean;
    mask?: boolean;
    maskClosable?: boolean;
    keyboard?: boolean;
    onClose?: () => void;
    priority?: boolean;
}

export type IModalForwardRefProps = {
    open: () => void;
    close: () => void;
    isOpened: boolean;
};

const Modal: ForwardRefExoticComponent<IModalProps & RefAttributes<IModalForwardRefProps>> =
    forwardRef(
        (
            {
                styleClass,
                children,
                type,
                closable = true,
                maskClosable = true,
                onClose,
                keyboard = true,
                mask = true,
                priority,
            },
            ref,
        ) => {
            const [closing, setClosing] = useState<boolean>(false);
            const [priorityOpened, setPriorityOpened] = useState(false);
            const fadeInAnimationRef: RefObject<{
                clearVisible: () => void;
                forceOpen: () => void;
            }> = useRef(null);
            const timeoutRef = useRef<NodeJS.Timeout | null>(null);
            const router = useRouter();
            const {
                setCurrentModal,
                currentModal,
                previousModal,
                clearCurrentModal,
                clearPrevModal,
            } = useContext(ModalsContext);

            const open = () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                setClosing(false);

                if (priority) {
                    setPriorityOpened(true);
                } else {
                    setCurrentModal(type);
                }
                if (closing) {
                    fadeInAnimationRef.current?.forceOpen();
                }
            };

            const close = useCallback(() => {
                if (!closing) {
                    setClosing(true);

                    if (router.query?.type === type) {
                        const query = { ...router.query };
                        delete query.type;
                        delete query.directory;
                        delete query.subdirectory;
                        router.replace({
                            query,
                        });
                    }

                    fadeInAnimationRef.current?.clearVisible();

                    if (type === previousModal) {
                        if (timeoutRef.current) {
                            clearTimeout(timeoutRef.current);
                        }
                        timeoutRef.current = setTimeout(() => {
                            setClosing(false);
                            clearPrevModal();
                        }, 700);
                    } else if (type === currentModal) {
                        if (timeoutRef.current) {
                            clearTimeout(timeoutRef.current);
                        }
                        timeoutRef.current = setTimeout(() => {
                            setClosing(false);
                            clearCurrentModal();
                        }, 700);
                    } else if (priorityOpened) {
                        setPriorityOpened(false);
                        setClosing(false);
                    }

                    onClose?.();
                }
            }, [
                closing,
                router,
                type,
                previousModal,
                currentModal,
                priorityOpened,
                onClose,
                clearPrevModal,
                clearCurrentModal,
            ]);

            const onKeydownHandler = useCallback(
                (e: KeyboardEvent) => {
                    if (e.keyCode === 27) {
                        if (priority) {
                            setPriorityOpened(false);
                        } else {
                            setCurrentModal(null);
                        }
                        onClose?.();
                    }
                },
                [onClose, priority, setCurrentModal],
            );

            useEffect(() => {
                if ((currentModal === type || priorityOpened) && keyboard) {
                    document.addEventListener("keydown", onKeydownHandler, false);
                }

                return () => {
                    if (keyboard) {
                        document.removeEventListener("keydown", onKeydownHandler, false);
                    }
                };
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [currentModal, type]);

            useEffect(() => {
                if (previousModal && type === previousModal) {
                    close();
                }
            }, [close, previousModal, type]);

            useImperativeHandle(ref, () => ({
                open: () => open(),
                close: () => close(),
                isOpened: type === (currentModal || previousModal),
            }));

            if (currentModal === type || previousModal === type || priorityOpened) {
                const containerElement = document.getElementById(
                    priority ? "modal-priority" : "modal-root",
                );

                return containerElement
                    ? createPortal(
                          <FadeInAnimation ref={fadeInAnimationRef} variant="modalBaseAnimate">
                              <section
                                  className={classnames(styles.modalContainer, styleClass)}
                                  data-testid="modal">
                                  {mask && (
                                      <div
                                          className={styles.modalBackdrop}
                                          onClick={maskClosable ? close : undefined}
                                      />
                                  )}

                                  <section className="modal">
                                      {closable && (
                                          <i
                                              className={styles.closeModal}
                                              onClick={close}
                                              data-testid="modal-close-button"
                                          />
                                      )}
                                      {children}
                                  </section>
                              </section>
                          </FadeInAnimation>,
                          containerElement,
                      )
                    : null;
            }

            return null;
        },
    );

export default Modal;
