import { ChatServiceNameEnum } from "@finbackoffice/fe-core";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import Script from "next/script";
import { FC, useMemo, useCallback } from "react";

const ChatScript: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");

    const onLiveChatScriptLoad = useCallback(() => {
        LiveChatWidget.init();
    }, []);

    const onTawkScriptLoad = useCallback(() => {
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.autoStart = true;
    }, []);

    const renderChatScript = useMemo(() => {
        let content;
        switch (COMMON_SITE_CONFIGS.chatService.name) {
            case ChatServiceNameEnum.LiveChat:
                content = (
                    <Script
                        src="/assets/js/livechat.js"
                        id={COMMON_SITE_CONFIGS.chatService.id}
                        onLoad={onLiveChatScriptLoad}
                        async
                    />
                );
                break;
            case ChatServiceNameEnum.Zendesk:
                content = (
                    <Script
                        id="ze-snippet"
                        async
                        src={`https://static.zdassets.com/ekr/snippet.js?key=${COMMON_SITE_CONFIGS.chatService.id}`}
                    />
                );
                break;
            case ChatServiceNameEnum.Tawk:
                content = (
                    <Script
                        id={COMMON_SITE_CONFIGS.chatService.id}
                        async
                        src={`https://embed.tawk.to/${COMMON_SITE_CONFIGS.chatService.id}`}
                        onLoad={onTawkScriptLoad}
                    />
                );
                break;
            default:
                content = null;
        }

        return !!COMMON_SITE_CONFIGS.chatService.id && content;
    }, [
        COMMON_SITE_CONFIGS.chatService.id,
        COMMON_SITE_CONFIGS.chatService.name,
        onLiveChatScriptLoad,
        onTawkScriptLoad,
    ]);

    return renderChatScript;
};

export default ChatScript;
