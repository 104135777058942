import { useContext } from "react";
import { useRouter } from "next/router";
import { FeedContext, WebsocketContext } from "@finbackoffice/site-core";
import { useSocketStorage, FeedTypeEnum } from "./useSocketStorage";

export const useSelectedLiveGameId = (): number | null => {
    const { hasLiveFeedGames } = useContext(FeedContext);
    const router = useRouter();
    const { getDefaultLiveGameParams } = useSocketStorage(FeedTypeEnum.live);
    const { socketClient } = useContext(WebsocketContext);
    const [, , routeGameId] = router.query.liveGameRoutes ?? [];

    if (!hasLiveFeedGames) {
        return null;
    }

    const gameId = parseInt(routeGameId);

    return socketClient.getLiveEvent(gameId)?.id ?? getDefaultLiveGameParams()?.gameId ?? null;
};

export const useSelectedLiveSportSlug = (): string => {
    const router = useRouter();
    const gameId = useSelectedLiveGameId();
    const { socketClient } = useContext(WebsocketContext);
    const [sportSlugFromUrl] = router.query.liveGameRoutes ?? [];

    if (!gameId) {
        return sportSlugFromUrl;
    }

    const game = socketClient.getLiveEvent(gameId);
    return game ? game.sport_slug : sportSlugFromUrl;
};

export const useSelectedLiveSportId = (): number | null => {
    const gameId = useSelectedLiveGameId();
    const { socketClient } = useContext(WebsocketContext);

    if (!gameId) {
        return null;
    }

    return socketClient.getLiveEvent(gameId)?.sport_id || null;
};

export const useSelectedLiveRegionId = (): number | null => {
    const gameId = useSelectedLiveGameId();
    const { socketClient } = useContext(WebsocketContext);

    if (!gameId) {
        return null;
    }

    return socketClient.getLiveEvent(gameId)?.category_id || null;
};
