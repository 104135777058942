import {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DragItem } from "components/base/draggable/Draggable";

type IDraggableContext = {
    moveItem: (id: string, top: number, left: number) => void;
    addItem: (item: DraggableItem) => void;
    removeItem: (id: string) => void;
    isActive: (id: string) => boolean;
    clearAllItems: () => void;
    setIsDragging: (val: boolean) => void;
    isDragging: boolean;
    items: DraggableItem[];
};

export type DraggableItem = {
    id: string;
    top: number;
    left: number;
    payload?: Record<string, any>;
};

export const DraggableContext = createContext<IDraggableContext>(null as any);

export const DropArea: FC = () => {
    const { moveItem } = useContext(DraggableContext);
    const [{ isDragging }, drop] = useDrop(
        () => ({
            accept: "draggable",
            drop(item: DragItem, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset() as {
                    x: number;
                    y: number;
                };

                const left = Math.round(item.left + delta.x);
                const top = Math.round(item.top + delta.y);

                moveItem(item.id, top, left);
                return undefined;
            },
            collect: (monitor) => {
                return {
                    isDragging: monitor.canDrop(),
                };
            },
        }),
        [moveItem],
    );

    return (
        <div
            id="draggables"
            style={
                isDragging
                    ? {
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1000,
                      }
                    : undefined
            }
            // @ts-expect-error: FIXME
            ref={drop}
        />
    );
};

export const DraggableProvider: FC<PropsWithChildren> = ({ children }) => {
    const [items, setItems] = useState<DraggableItem[]>([]);
    const [isDragging, setIsDragging] = useState(false);

    const moveItem = useCallback(
        (id: string, top: number, left: number) => {
            const result = items.map((item) => {
                if (item.id === id) {
                    return { ...item, top, left };
                }

                return item;
            });

            setItems(result);
        },
        [items],
    );

    const addItem = useCallback((item: DraggableItem) => {
        const { top, id, left, payload } = item;
        const data = { id, top, left, payload };
        if (!payload) {
            delete data.payload;
        }
        setItems((state) => [...state, data]);
    }, []);

    const removeItem = useCallback((id: string) => {
        setItems((state) => state.filter((item) => item.id !== id));
    }, []);

    const clearAllItems = useCallback(() => setItems([]), []);

    const isActive = useCallback(
        (id: string) => Boolean(items.find((item) => item.id === id)),
        [items],
    );

    const value = useMemo(
        () => ({
            moveItem,
            addItem,
            removeItem,
            items,
            isActive,
            clearAllItems,
            setIsDragging,
            isDragging,
        }),
        [addItem, clearAllItems, isActive, isDragging, items, moveItem, removeItem],
    );

    return (
        <DraggableContext.Provider value={value}>
            <DndProvider debugMode backend={HTML5Backend}>
                {children}
            </DndProvider>
        </DraggableContext.Provider>
    );
};
