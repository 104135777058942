import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import {
    IBEMarketFeedResponse,
    IClientError,
    IEventFeedResponse,
} from "@finbackoffice/websocket-client";
import {
    TemplateIdsMap,
    MarketGroupIds,
    sortByNumberAfterLastSpace,
    sortByNumberBetweenBrackets,
    sortSetWinnerMarkets,
    getMarketGroupId,
    MarketGroupData,
} from "@finbackoffice/fe-core";
import { BetSlipContext, useWebsocketUpdates } from "@finbackoffice/site-core";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { MarketOutcomeStatus, MarketStatus } from "@finbackoffice/enums";
import { MarketUpdatesContext } from "contexts";
import { IGameData } from "types/GameData";
import { RouteNames } from "utils/constants";
import { useGetCurrentFeedType } from "./useGetCurrentFeed";

const excludeGroups = (ids: number[]) => {
    return MarketGroupIds.filter((id) => !ids.includes(id));
};

const filterIdsByTemplateId = (ids: string[], templateId: number) =>
    ids.filter((id) => id.includes(templateId.toString()));

export const useGame = (gameId: number) => {
    const router = useRouter();
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);
    const feed = useGetCurrentFeedType();
    const [gameData, setGameData] = useState<IGameData | null>(null);

    const sportId = gameData?.sport_id;
    const homeName = gameData?.home_team?.name || "";
    const awayName = gameData?.away_team?.name || "";

    const gameUpdateHandler = useCallback(
        (data: IEventFeedResponse, error: IClientError) => {
            if (!error) {
                const result: IGameData = { ...data, market_groups: {} };
                const marketGroupIds = excludeGroups([
                    TemplateIdsMap.QUARTERNUMBER_QUARTER_TOTAL,
                    TemplateIdsMap.QUARTERNUMBER_QUARTER_HANDICAP,
                    TemplateIdsMap.SETNUMBER_SET_POINT_HANDICAPS,
                    TemplateIdsMap.SETNUMBER_SET_TOTAL_POINTS,
                ]);

                console.debug("Game Statuses: ", data.status, data.scoreboard?.status);

                result.markets?.sort(sortSetWinnerMarkets);

                result.markets?.forEach((market: IBEMarketFeedResponse) => {
                    if (marketGroupIds.includes(market.template_id)) {
                        if (result.market_groups.hasOwnProperty(getMarketGroupId(market))) {
                            result.market_groups[getMarketGroupId(market)].markets.push(market);
                        } else {
                            result.market_groups[getMarketGroupId(market)] = new MarketGroupData(
                                market,
                                getMarketGroupId(market),
                                result.home_team?.name || "",
                                result.away_team?.name || "",
                            );
                        }
                    } else {
                        result.market_groups[market.id] = new MarketGroupData(
                            market,
                            market.id.toString(),
                            result.home_team?.name || "",
                            result.away_team?.name || "",
                        );
                    }
                });

                result.markets = [];
                const groupIds = Object.keys(result.market_groups);

                [
                    TemplateIdsMap.HANDICAP,
                    TemplateIdsMap.HANDICAP_INCL_EXTRA_INNINGS,
                    TemplateIdsMap.INNINGS_1TO5_HANDICAP,
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.INNINGNUMBER_INNING_HANDICAP),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_HANDICAP,
                    ),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.QUARTERNUMBER_QUARTER_HANDICAP,
                    ),
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.PERIODNUMBER_PERIOD_HANDICAP),
                ].forEach((item) => {
                    result.market_groups[item]?.markets.sort((a, b) =>
                        sortByNumberBetweenBrackets(a, b),
                    );
                });

                [
                    TemplateIdsMap.TOTAL,
                    TemplateIdsMap.COMPETITOR1_TOTAL,
                    TemplateIdsMap.COMPETITOR2_TOTAL,
                    TemplateIdsMap.TOTAL_1ST_HALF,
                    TemplateIdsMap.TOTAL_1ST_HALF_COMPETITOR1,
                    TemplateIdsMap.TOTAL_1ST_HALF_COMPETITOR2,
                    TemplateIdsMap.TOTAL_2ND_HALF,
                    TemplateIdsMap.TOTAL_2ND_HALF_COMPETITOR1,
                    TemplateIdsMap.TOTAL_2ND_HALF_COMPETITOR2,
                    TemplateIdsMap.TOTAL_POINTS,
                    TemplateIdsMap.TOTAL_INCL_EXTRA_INNINGS,
                    TemplateIdsMap.INNINGS_1TO5_TOTAL,
                    TemplateIdsMap.INNINGS_1TO5_COMPETITOR1_TOTAL,
                    TemplateIdsMap.INNINGS_1TO5_COMPETITOR2_TOTAL,
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.INNINGNUMBER_INNING_TOTAL),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_TOTAL,
                    ),
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.QUARTERNUMBER_QUARTER_TOTAL),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.QUARTERNUMBER_QUARTER_COMPETITOR1_TOTAL,
                    ),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.QUARTERNUMBER_QUARTER_COMPETITOR2_TOTAL,
                    ),
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.PERIODNUMBER_PERIOD_TOTAL),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_COMPETITOR1_TOTAL,
                    ),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_COMPETITOR2_TOTAL,
                    ),
                ].forEach((item) => {
                    result.market_groups[item]?.markets.sort((a, b) =>
                        sortByNumberAfterLastSpace(a, b),
                    );
                });

                setGameData(result);
            } else if (error.statusCode === 404) {
                router.replace({
                    pathname: `/${RouteNames.SPORT}/${feed}`,
                });
            }
        },
        [feed, router],
    );

    const payload = useMemo(() => ({ event_id: gameId }), [gameId]);
    useWebsocketUpdates("subscribe_event", gameUpdateHandler, true, payload);

    useEffect(() => {
        return () => {
            if (gameId) {
                setGameData(null);
            }
        };
    }, [gameId]);

    const getUpdatedFavorites = useCallback(
        (storage: Storage, marketType: string) => {
            if (sportId) {
                if (storage && storage.hasOwnProperty(sportId)) {
                    const index: number = storage[sportId].indexOf(marketType);

                    if (index === -1) {
                        storage[sportId].push(marketType);
                    } else {
                        storage[sportId].splice(index, 1);
                    }
                } else {
                    storage[sportId] = [marketType];
                }
            }

            return storage;
        },
        [sportId],
    );

    const addBet = useCallback(
        (
            marketGroupId: string,
            marketName: string,
            marketStatus: MarketStatus,
            marketId: number,
            outcome: IMarketOutcomeFeedResponse,
        ) => {
            if (!isOutcomeSelected(outcome.id)) {
                addBetItem({
                    gameId,
                    marketGroupId,
                    marketId,
                    outcomeId: outcome.id,
                    team1Name: homeName,
                    team2Name: awayName,
                    marketName,
                    marketStatus,
                    outcomeName: outcome.name ?? "",
                    outcomeValue: outcome.odds,
                    outcomeStatus: MarketOutcomeStatus.Active,
                    outcomeInitValue: outcome.odds,
                    stake: "",
                    loading: false,
                    accepted: false,
                });
                subscribeMarket(gameId, marketId, outcome.id);
            } else {
                removeBetItem(outcome.id);
                unsubscribeMarket(outcome.id);
            }
        },
        [
            awayName,
            isOutcomeSelected,
            gameId,
            homeName,
            subscribeMarket,
            unsubscribeMarket,
            addBetItem,
            removeBetItem,
        ],
    );

    return { gameData, getUpdatedFavorites, addBet };
};
