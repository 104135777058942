import { FC, VideoHTMLAttributes } from "react";

type IProps = VideoHTMLAttributes<HTMLVideoElement & HTMLSourceElement> & {
    src: string;
};

const Video: FC<IProps> = ({ src, poster, width, height, onError, muted = true }) => {
    if (!src.includes("mp4")) {
        return null;
    }

    return (
        <video
            autoPlay
            muted={muted}
            playsInline
            loop
            poster={poster}
            width={width}
            height={height}>
            <source src={src} type="video/mp4" onError={onError} />
        </video>
    );
};

export default Video;
