import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ISocketResponse } from "@finbackoffice/websocket-client";
import { ITicketQuery, ITicketResponse } from "@finbackoffice/clientbff-client";
import { format } from "date-fns";
import { Currency, TicketStatus, WalletType } from "@finbackoffice/enums";
import {
    ClientBFFContext,
    PaginationType,
    DEFAULT_PAGINATION,
    useWebsocketUpdates,
    AuthContext,
} from "@finbackoffice/site-core";

type IBetsState = {
    bets: ITicketResponse[] | null;
    loading: boolean;
};

export type ISportBetsFilterState = {
    status: string;
    from: Date | null;
    to: Date | null;
    search: string;
    wallet: string;
};

type IUseTicketProps = {
    pagination?: Partial<PaginationType>;
    filter?: Partial<ISportBetsFilterState>;
};

export const TICKET_STATUS = {
    active: "Active",
    settled: "Settled",
};

const TICKET_STATUS_MAP = {
    [TICKET_STATUS.settled]: [
        TicketStatus.Win,
        TicketStatus.Lose,
        TicketStatus.Cancelled,
        TicketStatus.Void,
        TicketStatus.WinVoid,
        TicketStatus.LoseVoid,
        TicketStatus.DeadHeat,
    ],
    [TICKET_STATUS.active]: [TicketStatus.Accepted],
};

function getFilterQuery(filter: ISportBetsFilterState): Partial<ITicketQuery> {
    const filterQuery: Partial<ITicketQuery> = {};

    if (filter.from) {
        filterQuery.from = format(filter.from, "yyyy-MM-dd HH:mm:ss");
    }

    if (filter.to) {
        filterQuery.to = format(filter.to, "yyyy-MM-dd HH:mm:ss");
    }

    if (filter.status) {
        filterQuery.status = TICKET_STATUS_MAP[filter.status];
    } else {
        const statuses: TicketStatus[] = [];
        Object.values(TicketStatus).forEach((val) => {
            val !== TicketStatus.Rejected && statuses.push(val);
        });
        filterQuery.status = statuses;
    }

    if (filter.search) {
        if (filter.search.startsWith("BR")) {
            filterQuery.short_id = [filter.search];
        } else {
            const eventId = parseInt(filter.search);

            if (eventId) {
                filterQuery.event_id = eventId;
            }
        }
    }

    if (filter.wallet) {
        const [currency, type] = filter.wallet.split("-") as [Currency, WalletType];
        if (currency && type) {
            filterQuery.currency = currency;
            filterQuery.wallet_type = type;
        }
    }

    return filterQuery;
}

export const useTickets = (props?: IUseTicketProps) => {
    const { userToken } = useContext(AuthContext);
    const client = useContext(ClientBFFContext);
    const [betsState, setBetsState] = useState<IBetsState>({
        bets: null,
        loading: false,
    });
    const [pagination, setPagination] = useState<PaginationType>({
        ...DEFAULT_PAGINATION,
        ...props?.pagination,
    });
    const [filter, setFilter] = useState<ISportBetsFilterState>({
        from: null,
        to: null,
        status: "",
        search: "",
        wallet: "",
        ...props?.filter,
    });
    const [ticketUpdate, setTicketUpdate] = useState<ISocketResponse<"ticket"> | null>(null);
    const handleTicket = useCallback((data: ISocketResponse<"ticket">) => {
        setTicketUpdate(data);
    }, []);

    const params = useMemo(() => ({ token: userToken || "" }), [userToken]);

    useWebsocketUpdates("ticket", handleTicket, true, params);

    useEffect(() => {
        if (ticketUpdate && betsState.bets) {
            const index = betsState.bets.findIndex((ticket) => ticketUpdate.id === ticket.id);
            if (index !== -1) {
                const bets = [...betsState.bets];
                bets[index] = ticketUpdate;
                setBetsState((state) => ({
                    ...state,
                    bets,
                }));
            } /* else if (pagination.page === 1 && !filter.search && !filter.status) {
                console.log(
                    new Date(data.created_at).getTime() >= new Date(filter.from!).getTime(),
                    new Date(data.created_at).getTime() <= new Date(filter.to!).getTime(),
                );
            } */
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketUpdate]);

    useEffect(() => {
        let isMounted = true;

        const loadBets = async () => {
            setBetsState((state) => ({
                ...state,
                loading: true,
            }));
            try {
                const response = await client.queryTickets({
                    page: pagination.page,
                    items_per_page: pagination.items_per_page,
                    /* order_by: [
                        {
                            field: "bet_settled_at",
                            order: Order.DESC
                        }
                    ], */
                    ...getFilterQuery(filter),
                });

                if (isMounted) {
                    setBetsState((state) => ({
                        ...state,
                        loading: false,
                        bets: response.items,
                    }));
                    setPagination((state) => ({
                        ...state,
                        total_pages: response.total_pages,
                    }));
                }
            } catch (error) {
                if (isMounted) {
                    setBetsState((state) => ({
                        ...state,
                        loading: false,
                    }));
                }
            }
        };

        loadBets();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, pagination.items_per_page, pagination.page]);

    return { betsState, filter, setFilter, pagination, setPagination };
};
