import { BrowserStorageContext, BrowserStorageIdsEnum } from "@finbackoffice/site-core";
import { useSearchParams } from "next/navigation";
import Script from "next/script";
import { FC, useContext, useEffect } from "react";

const AFFISE_INIT_QUERIES = ["clickid", "click_id"];

export const AffiseInitScript: FC = () => {
    const searchParams = useSearchParams();
    const { set } = useContext(BrowserStorageContext);

    useEffect(() => {
        if (searchParams.has(BrowserStorageIdsEnum.UTM_CAMPAIGN)) {
            set(
                BrowserStorageIdsEnum.UTM_CAMPAIGN,
                searchParams.get(BrowserStorageIdsEnum.UTM_CAMPAIGN) || "",
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    for (const key of searchParams.keys()) {
        if (AFFISE_INIT_QUERIES.includes(key)) {
            return (
                <Script
                    src="/assets/js/affise-init.js"
                    id="affise-init"
                    data-clickid={searchParams.get(key)}
                    async
                />
            );
        }
    }

    return null;
};
