import { TranslationScopes } from "@finbackoffice/enums";
import {
    ClientBFFContext,
    I18nContext,
    LocaleContext,
    getDesktopTranslations,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { useContext, useEffect, useState } from "react";

export const useLoadI18nextResource = (value: TranslationScopes) => {
    const VERSION = useRuntimeConfig("VERSION");
    const client = useContext(ClientBFFContext);
    const { locale } = useContext(LocaleContext);
    const { i18n } = useContext(I18nContext);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        async function load() {
            if (i18n.hasResourceBundle(locale, value)) {
                setIsReady(true);
                return;
            }

            const response = await getDesktopTranslations({
                client,
                includeLocalTranslations:
                    process.env.NODE_ENV === "development" || VERSION === "local",
                request: {
                    language: locale,
                    scopes: [value],
                },
            });
            i18n.addResourceBundle(locale, value, response[value]);
            setIsReady(true);
        }

        void load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isReady;
};
