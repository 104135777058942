import { useState, useEffect, useCallback, useContext } from "react";
import Image from "next/image";
import classnames from "classnames";
import { LocaleContext, useRuntimeConfig } from "@finbackoffice/site-core";
import { ComponentProps } from "react";

type ImageProps = Omit<ComponentProps<typeof Image>, "src">;

export interface ImgProps extends ImageProps {
    source: string;
    alt: string;
    fallbackSrc?: string;
    wrapperClassName?: string;
    style?: React.CSSProperties;
    onFallback?: () => void;
}

const Img: React.FC<ImgProps> = ({
    source,
    fallbackSrc,
    wrapperClassName,
    alt,
    style,
    onFallback,
    ...rest
}) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { locale } = useContext(LocaleContext);
    const [imgSrc, setImgSrc] = useState(source);
    const [ready, setReady] = useState(false);

    const handleError = useCallback(() => {
        if (fallbackSrc) {
            setImgSrc(fallbackSrc);
            onFallback?.();
        } else if (source.includes(locale)) {
            setImgSrc(source.replace(locale, COMMON_SITE_CONFIGS.defaultLocale));
        }
    }, [fallbackSrc, source, locale, onFallback, COMMON_SITE_CONFIGS.defaultLocale]);

    const onLoadCallback = useCallback(() => setReady(true), []);

    useEffect(() => {
        setImgSrc(source);
    }, [source]);

    return (
        <span className={classnames("imageNext", wrapperClassName)}>
            {imgSrc && (
                <Image
                    quality={100}
                    alt={alt}
                    src={imgSrc}
                    onError={handleError}
                    style={{
                        ...style,
                        opacity: ready ? 1 : 0,
                        transition: "opacity 0.3s",
                    }}
                    unoptimized
                    {...rest}
                    onLoad={onLoadCallback}
                />
            )}
        </span>
    );
};

export default Img;
