import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from "react";

interface IMultiViewContext {
    gameIds: number[];
    columnMode: string;
    addGame: (id: number) => void;
    removeGame: (id: number) => number[];
    setColumnMode: Dispatch<SetStateAction<string>>;
    removeAllGames: () => void;
    reorderGames: (dragIndex: number, hoverIndex: number) => void;
}

export const MultiViewContext = createContext<IMultiViewContext>(null as any);

export const MultiViewProvider: FC<PropsWithChildren> = ({ children }) => {
    const [gameIds, setGameIds] = useState<number[]>([]);
    const [columnMode, setColumnMode] = useState("3");

    const addGame = useCallback((id: number) => {
        setGameIds((state) => [...state, id]);
    }, []);

    const removeGame = useCallback(
        (id: number) => gameIds.filter((gameId) => gameId !== id),
        [gameIds],
    );

    const reorderGames = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const result = [...gameIds];

            result.splice(dragIndex, 1);
            result.splice(hoverIndex, 0, gameIds[dragIndex]);

            setGameIds(result);
        },
        [gameIds],
    );

    const removeAllGames = useCallback(() => setGameIds([]), []);

    const value = useMemo(
        () => ({
            gameIds,
            columnMode,
            addGame,
            setColumnMode,
            removeGame,
            removeAllGames,
            reorderGames,
        }),
        [gameIds, columnMode, addGame, setColumnMode, removeGame, reorderGames, removeAllGames],
    );

    return <MultiViewContext.Provider value={value}>{children}</MultiViewContext.Provider>;
};
