import { FC, PropsWithChildren, createContext, useCallback, useMemo } from "react";
import { useNotification } from "rc-notification";
import SiteNotification, {
    ISiteNotificationVariant,
} from "components/base/notification/SiteNotification";

export interface INoticeEvent {
    type: "error" | "info" | "warning";
    title: string;
    message: string;
}

export type INotificationContext = {
    notice: (event: INoticeEvent, variant?: ISiteNotificationVariant) => void;
};

export const NotificationContext = createContext<INotificationContext>(null as any);

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
    const [instance, holder] = useNotification({
        maxCount: 3,
        style: () => ({
            width: "100%",
            height: "100%",
            top: 0,
        }),
    });

    const notice = useCallback(
        (event: INoticeEvent, variant: ISiteNotificationVariant = "default") => {
            instance?.open({
                className: `notification-${variant}`,
                key: event.title,
                content: (
                    <SiteNotification
                        variant={variant}
                        type={event.type}
                        title={event.title}
                        message={event.message}
                        instance={instance}
                    />
                ),
                duration: variant === "modal" ? 60 * 3 : 3,
                closable: true,
                style:
                    variant === "modal"
                        ? {
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                              position: "absolute",
                          }
                        : {
                              bottom: 15,
                              right: 15,
                              position: "absolute",
                          },
            });
        },
        [instance],
    );

    const value = useMemo(() => ({ notice }), [notice]);

    return (
        <NotificationContext.Provider value={value}>
            {children}
            {holder}
        </NotificationContext.Provider>
    );
};
