export enum RouterQuery {
    Reels = "reels",
    Login = "login",
    Signup = "signup",
    Account = "account",
    Bets = "bets",
    Transactions = "transactions",
    Referral = "referral",
    Messages = "messages",
    Bonuses = "bonuses",
    Details = "details",
    Agent = "agent",
    Report = "report",
    Players = "players",
    CreatePlayer = "create-player",
    Sport = "sport",
    Casino = "casino",
    Personal = "personal",
    Preferances = "preferences",
    "2FA" = "2fa",
    ResponsibleGaming = "responsible-gaming",
    Search = "search",
    Inbox = "inbox",
    Sent = "sent",
    Starred = "starred",
    Compose = "compose",
    Chat = "chat",
    LiveCasino = "livecasino",
    Slots = "slots",
    Promo2 = "promo-2",
    Promo3 = "promo-3",
    Promo4 = "promo-4",
    Promo5 = "promo-5",
    Promo6 = "promo-6",
    Casino2 = "casino-2",
}
