import { FC, cloneElement, ReactElement, PropsWithChildren } from "react";
import { ITranslateProps, useTranslation } from "@finbackoffice/site-core";
import { TranslationScopes } from "@finbackoffice/enums";

const Translate: FC<PropsWithChildren<ITranslateProps>> = ({
    tid,
    replace,
    dangerous,
    children,
    namespace,
    text,
}) => {
    const { t } = useTranslation(namespace || TranslationScopes.Common);

    if (dangerous) {
        return cloneElement(children as ReactElement, {
            dangerouslySetInnerHTML: {
                __html: tid ? (replace ? t(tid, replace) : t(tid)) : text,
            },
        });
    }

    return tid ? (replace ? t(tid, replace) : t(tid)) : null;
};

export default Translate;
