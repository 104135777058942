export const RouteNames = {
    SPORT: "sport",
    LIVE: "live",
    PREMATCH: "prematch",
    CASINO: "casino",
    LIVE_CASINO: "livecasino",
    SLOTS: "slots",
    FAVORITES: "favorites",
    REFERRAL: "referral",
    POLICIES: "policies",
    SPORTSBOOK: "sportsbook",
    TERMS: "terms",
    PRIVACY: "privacy",
    PAYMENTS: "payments",
    RESPONSIBLE_GAMING: "responsible-gaming",
    COOKIES: "cookies",
    SELF_EXCLUSION: "self-exclusion",
    KYC: "kyc",
    FAIRNESS_RNG_TESTING: "fairness-rng-testing",
    DISPUTE_RESOLUTION: "dispute-resolution",
    ANTI_MONEY_LAUNDERING: "anti-money-laundering",
    BONUSES: "bonuses",
    CASHBACK: "cashback",
    BONUS_REFERRAL: "bonus-referral",
    PROMOTIONS: "promotions",
    MULTI_VIEW: "multi-view",
    CALENDAR: "calendar",
};
