import { useCallback, useContext, useEffect, useMemo } from "react";
import { AuthContext, UserAccountContext, useWebsocketUpdates } from "@finbackoffice/site-core";
import { IClientError, ISocketResponse } from "@finbackoffice/websocket-client";

export const useSubscribeWalletsById = () => {
    const { updateBalance } = useContext(UserAccountContext);
    const { userToken } = useContext(AuthContext);

    const handleWalletUpdates = useCallback(
        (data: ISocketResponse<"wallet">, error: IClientError) => {
            if (!error) {
                updateBalance(data);
            } else {
                console.error(error);
            }
        },
        [updateBalance],
    );

    const params = useMemo(() => ({ token: userToken || "" }), [userToken]);

    const walletSocket = useWebsocketUpdates("wallet", handleWalletUpdates, false, params);

    useEffect(() => {
        if (userToken) {
            // Subscribe on "wallet" messages only for logged in users
            walletSocket.subscribe();
        }
        return () => {
            if (userToken) {
                walletSocket.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userToken]);
};
