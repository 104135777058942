import { useCallback, useContext } from "react";
import {
    setMinutes,
    setHours,
    setSeconds,
    format,
    getTime,
    endOfDay,
    addDays,
    startOfDay,
    subDays,
    differenceInDays,
    formatDistanceToNowStrict,
    setDefaultOptions,
    differenceInYears,
} from "date-fns";
import { ru, enGB, ko } from "date-fns/locale";
import { LocaleContext } from "@finbackoffice/site-core";

export const DEFAULT_DATE_FORMAT = "dd MMM yyyy";

const locales: Record<string, any> = { en: enGB, ko, ru };

export const useDate = () => {
    const { locale } = useContext(LocaleContext);
    setDefaultOptions({ locale: locales[locale] || locales.en });

    const formatDate = useCallback((date: number | Date, formatStr: string) => {
        return format(date, formatStr);
    }, []);

    return {
        formatDate,
        getTime,
        endOfDay,
        addDays,
        startOfDay,
        subDays,
        setMinutes,
        setHours,
        setSeconds,
        differenceInDays,
        differenceInYears,
        formatDistanceToNowStrict,
    };
};
