import { useCallback, useContext, useEffect } from "react";
import { FeedContext, WebsocketContext, useWebsocketUpdates } from "@finbackoffice/site-core";
import { IClientError, ISocketResponse } from "@finbackoffice/websocket-client";
import { useSocketStorage, FeedTypeEnum } from "./useSocketStorage";

export const useLiveSports = (): void => {
    const { getSports, getTopLeagues } = useSocketStorage(FeedTypeEnum.live);
    const { connected } = useContext(WebsocketContext);
    const { setLive } = useContext(FeedContext);

    const handler = useCallback(
        (_nothing: ISocketResponse<"live">, error: IClientError) => {
            if (!error) {
                const sports = getSports();
                const topLeagues = getTopLeagues();
                setLive({
                    loading: false,
                    sports,
                    topLeagues,
                    totalGames: sports.reduce((acc, s) => acc + s.games_count, 0),
                });
            } else {
                console.error(error);
            }
        },
        [getSports, getTopLeagues, setLive],
    );

    useWebsocketUpdates("live", handler);

    useEffect(() => {
        return () => {
            setLive({
                loading: true,
                sports: [],
                totalGames: 0,
                topLeagues: [],
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected]);
};
