import { useContext } from "react";
import { useRouter } from "next/router";
import { ITopLeagueFeedResponse } from "@finbackoffice/websocket-client";
import { NextRouter } from "next/dist/shared/lib/router/router";
import { FeedContext, WebsocketContext } from "@finbackoffice/site-core";
import { PrematchContext } from "contexts";

type ISelectedPrematchParams = {
    sportSlug: string;
    categoryId: number;
    tournamentId: number;
};

export function getDefaultParamsFromTopLeagues(
    topLeagues: ITopLeagueFeedResponse[],
): ISelectedPrematchParams | null {
    if (topLeagues.length === 0) {
        return null;
    }

    const [firstTopLeague] = topLeagues;

    return {
        sportSlug: firstTopLeague.sport_slug,
        categoryId: firstTopLeague.category_id,
        tournamentId: firstTopLeague.id,
    };
}

function getDefaultParamsFromUrl(router: NextRouter): ISelectedPrematchParams | null {
    const [sportSlug, categoryId, tournamentId] = router.query.prematchGameRoutes ?? [];

    if (sportSlug && categoryId && tournamentId) {
        return {
            sportSlug,
            categoryId: parseInt(categoryId),
            tournamentId: parseInt(tournamentId),
        };
    }

    return null;
}

export const useSelectedPrematchParams = (): ISelectedPrematchParams | null => {
    const { prematchTopLeagues, hasPrematchSports } = useContext(FeedContext);
    const router = useRouter();
    const defaultUrlParams = getDefaultParamsFromUrl(router);
    const defaultTopLeaguesParams = getDefaultParamsFromTopLeagues(prematchTopLeagues);
    const { socketClient } = useContext(WebsocketContext);

    if (!hasPrematchSports) {
        return null;
    }

    if (!defaultUrlParams) {
        return defaultTopLeaguesParams;
    }

    const sportFromUrl = socketClient
        .getPrematchSports()
        .find((sport) => sport.slug === defaultUrlParams.sportSlug);

    const categoryFromUrl = socketClient.getPrematchCategory(defaultUrlParams.categoryId);

    if (!sportFromUrl || !categoryFromUrl || !defaultUrlParams) {
        return defaultTopLeaguesParams;
    }

    const tournamentFromUrl = socketClient.getPrematchTournament(defaultUrlParams.tournamentId);

    if (!tournamentFromUrl) {
        const [firstTournament] = socketClient.getPrematchTournaments(defaultUrlParams.categoryId);

        return {
            ...defaultUrlParams,
            tournamentId: firstTournament.id,
        };
    }

    return defaultUrlParams;
};

export const useSelectedPrematchTournamentId = (): number | null => {
    const prematchParams = useSelectedPrematchParams();

    if (!prematchParams) {
        return null;
    }

    return prematchParams.tournamentId;
};

export const useActiveTournamentId = (): number | null => {
    const { tournaments } = useContext(PrematchContext);

    if (tournaments.length === 0) {
        return null;
    }

    return tournaments[0].tournamentId;
};

export const useSelectedTopLeagueTournamentId = (): number | null => {
    const activeTournamentId = useActiveTournamentId();
    const { socketClient } = useContext(WebsocketContext);

    if (!activeTournamentId) {
        return null;
    }

    const selectedTopLeague = socketClient
        ?.getPrematchTopLeagues()
        ?.find((t) => t.id === activeTournamentId);

    return selectedTopLeague?.id ?? null;
};

export const useSelectedPrematchCategoryId = (): number | null => {
    const prematchParams = useSelectedPrematchParams();

    if (!prematchParams) {
        return null;
    }

    return prematchParams.categoryId;
};

export const useSelectedPrematchSportSlug = (): string | null => {
    const prematchParams = useSelectedPrematchParams();

    if (!prematchParams) {
        return null;
    }

    return prematchParams.sportSlug;
};

export const useSelectedPrematchGameId = (): number | null => {
    const router = useRouter();
    const { activeGameId } = useContext(PrematchContext);
    const [, , , routeGameId] = router.query.prematchGameRoutes ?? [];

    return routeGameId ? parseInt(routeGameId) : activeGameId;
};
