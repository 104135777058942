import { useRouter } from "next/router";
import { RouteNames } from "utils/constants";
import { FeedTypeEnum } from "./useSocketStorage";

export const useGetCurrentFeedType = (): FeedTypeEnum => {
    const router = useRouter();
    const [, , feed] = router.route.split("/");

    if (
        router.pathname.includes(RouteNames.CALENDAR) ||
        router.pathname.includes(RouteNames.MULTI_VIEW)
    ) {
        return FeedTypeEnum.live;
    }
    return feed as unknown as FeedTypeEnum;
};
