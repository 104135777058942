import { FC, PropsWithChildren, forwardRef, RefAttributes } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
    settings: Settings;
};

const SlickSlider: FC<Props & PropsWithChildren & RefAttributes<Slider>> = forwardRef(
    ({ children, settings }, ref) => {
        return (
            <Slider {...settings} ref={ref}>
                {children}
            </Slider>
        );
    },
);

export default SlickSlider;
