import { useCallback, useEffect, useState } from "react";
import { sortByScheduledTime } from "@finbackoffice/fe-core";
import { useWebsocketUpdates } from "@finbackoffice/site-core";
import { IClientError, ISocketResponse, IEventFeedResponse } from "@finbackoffice/websocket-client";
import { EventStatus } from "@finbackoffice/enums";

export const useCalendar = (date: { from: number; to: number }): IEventFeedResponse[] => {
    const [games, setGamesData] = useState<IEventFeedResponse[]>([]);
    const [gamesMap] = useState<Map<number, IEventFeedResponse>>(new Map());

    const handleCalendarUpdates = useCallback(
        (events: ISocketResponse<"calendar">, error: IClientError) => {
            if (!error) {
                events.forEach((event) => {
                    if (
                        event.status === EventStatus.NotActive ||
                        event.status === EventStatus.Live
                    ) {
                        gamesMap.delete(event.id);
                    } else {
                        gamesMap.set(event.id, event);
                    }
                });
                const games = Array.from(gamesMap.values());
                const sorted_games = games.sort(sortByScheduledTime);
                setGamesData(sorted_games);
            } else {
                console.error(error);
            }
        },
        [gamesMap, setGamesData],
    );

    useEffect(() => {
        return () => gamesMap.clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useWebsocketUpdates("calendar", handleCalendarUpdates, true, date);

    return games;
};
